// App.js - WEB
import React, { Component, lazy, Suspense } from 'react';
import actionCable from 'actioncable';
const x = require('../../framework/src/config');
import { Helmet } from 'react-helmet';

import { Message } from '../../framework/src/Message';
import { BlockComponent } from '../../framework/src/BlockComponent';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../framework/src/RunEngine';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { isAuthenticated } from '../../components/src/utils';
import { ModalContainer } from 'react-router-modal';
import { StateProvider } from './globalState';
import { Box, CircularProgress } from '@material-ui/core';
import { isUserIpInGoogleIps } from './ip-ranges/checkIsGoogle';
import localStorage, { getStorageData } from '../../components/src/secureLocalStorage';
/* Resolution for CSS Loading Issue in Lazy Loading Setup:
Due to the previous development team not incorporating global 
styles within the individual blocks, a direct fix is now unfeasible.
An alternative solution would involve consolidating all global style 
files into a single global CSS file, which is a time-consuming process. 
As a temporary workaround, we have opted to statically import the necessary CSS files. */
import '../../blocks/alert/src/alert.css';
import '../../blocks/DifferentRooms/src/ModeratorList/Moderator.web.css';
import '../../blocks/DifferentRooms/src/RoomDetails/RulesOfEngagement.scss';
import '../../blocks/DifferentRooms/src/RoomDetails/RoomDetailsComponents/EditTake.scss';
import '../../blocks/DifferentRooms/src/RoomDetails/RoomDetailsComponents/HostTake.scss';
import '../../blocks/DifferentRooms/src/RoomDetails/RoomDetails.css';
import '../../blocks/DifferentRooms/src/MyRooms/MyRooms.css';
import '../../blocks/DifferentRooms/src/CategoriesAndRoomTypes/styles.css';
import '../../blocks/DifferentRooms/src/CreateRoom/createRoom.styles.css';
import '../../blocks/ThirdPartyAudioAndVideoIntergration/src/room/room.css';
import '../../blocks/ThirdPartyAudioAndVideoIntergration/src/room/Modals/Modals.css';
import '../../blocks/ThirdPartyAudioAndVideoIntergration/src/room/VideoChatScreen/VideoChatScreen.css';
import '../../blocks/ThirdPartyAudioAndVideoIntergration/src/room/SideBar/SideBar.css';
import '../../blocks/Settings5/src/settings/terms.css';
import '../../blocks/Settings5/src/settings/setting.web.css';
import '../../blocks/Settings5/src/settings/header.css';
import '../../blocks/Feedback/src/RoomFeedback.css';
import '../../blocks/onboardingguide/src/OnboardingDetails/styles.css';
import '../../blocks/onboardingguide/src/styles.css';
import '../../blocks/Payments/src/Wallet/MyWallet.scss';
import '../../blocks/Payments/src/Wallet/components/MobileResponsive/WalletResponsive.scss';
import '../../blocks/Payments/src/Wallet/components/accountDetailsForm.css';
import '../../blocks/Payments/src/Wallet/components/accountStatement.css';
import '../../blocks/Payments/src/refillwallet/refillWallet.css';
import '../../blocks/categoriessubcategories/src/categories/style.scss';
import '../../blocks/categoriessubcategories/src/categories/subcategory.scss';
import '../../blocks/email-account-registration/src/EmailRegister/dropdown.css';
import '../../blocks/email-account-registration/src/EmailRegister/verification.css';
import '../../blocks/email-account-registration/src/EmailRegister/styles.css';
import '../../blocks/user-profile-basic/src/UserPersonalProfile/styles.scss';
import '../../blocks/user-profile-basic/src/UserPersonalProfile/list.css';
import '../../blocks/user-profile-basic/src/UserEditProfile/dropdown.css';
import '../../blocks/user-profile-basic/src/UserEditProfile/verification.css';
import '../../blocks/user-profile-basic/src/UserEditProfile/UserEditProfileWeb.scss';
import '../../blocks/user-profile-basic/src/UserEditProfile/styles.css';
import '../../blocks/user-profile-basic/src/UserPublicProfile/UserProfile.css';
import '../../blocks/Referrals/src/Chat/Chat.css';
import '../../blocks/Referrals/src/HomePage/HomePage.css';

// Do not lazy load below component else api calls will not work
import HomeScreen from '../../components/src/HomeScreen';

const TopNav = lazy(() => import('../../components/src/TopNav'));
const Chat = lazy(() => import('../../blocks/Referrals/src/Chat/Chat.web'));
const InfoPage = lazy(() => import('../../blocks/info-page/src/InfoPageBlock'));
const AlertBlock = lazy(() => import('../../blocks/alert/src/AlertBlock.web'));
const VisualAnalytics = lazy(() =>
  import('../../blocks/visualanalytics/src/VisualAnalytics')
);
const AnonymousMode = lazy(() =>
  import('../../blocks/AnonymousMode/src/AnonymousMode')
);
const SocialMediaAccountLoginScreen = lazy(() =>
  import('../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen')
);
const MobileAccountLoginBlock = lazy(() =>
  import('../../blocks/mobile-account-login/src/MobileAccountLoginBlock')
);
const AdvancedSearch = lazy(() =>
  import('../../blocks/advancedsearch/src/AdvancedSearch')
);
const PunishmentAlerts = lazy(() =>
  import('../../blocks/PunishmentAlerts/src/PunishmentAlerts')
);
const OTPInputAuth = lazy(() =>
  import('../../blocks/otp-input-confirmation/src/OTPInputAuth')
);
const RolesPermissions2 = lazy(() =>
  import('../../blocks/RolesPermissions2/src/RolesPermissions2')
);
const Payments = lazy(() => import('../../blocks/Payments/src/Payments'));
const ConversationHistory2 = lazy(() =>
  import('../../blocks/ConversationHistory2/src/ConversationHistory2')
);
const PaymentAdmin2 = lazy(() =>
  import('../../blocks/PaymentAdmin2/src/PaymentAdmin2')
);
const Customform = lazy(() => import('../../blocks/customform/src/Customform'));
const TabletSupport65 = lazy(() =>
  import('../../blocks/TabletSupport65/src/TabletSupport65')
);
const CfPayoutBankApi = lazy(() =>
  import('../../blocks/CfPayoutBankApi/src/CfPayoutBankApi')
);
const Pushnotifications = lazy(() =>
  import('../../blocks/pushnotifications/src/Pushnotifications')
);
const PointSystemhearts = lazy(() =>
  import('../../blocks/PointSystemhearts/src/PointSystemhearts')
);
const ForgotPassword = lazy(() =>
  import('../../blocks/forgot-password/src/ForgotPassword')
);
const ForgotPasswordOTP = lazy(() =>
  import('../../blocks/forgot-password/src/ForgotPasswordOTP')
);
const NewPassword = lazy(() =>
  import('../../blocks/forgot-password/src/NewPassword')
);
const DeepLinking = lazy(() =>
  import('../../blocks/DeepLinking/src/DeepLinking')
);
const Notifications = lazy(() =>
  import('../../blocks/notifications/src/Notifications')
);
const Analytics = lazy(() => import('../../blocks/analytics/src/Analytics'));
const GiveHearts = lazy(() => import('../../blocks/GiveHearts/src/GiveHearts'));
const LinkShare = lazy(() => import('../../blocks/LinkShare/src/LinkShare'));
const ConnectionList = lazy(() =>
  import('../../blocks/user-profile-basic/src/UserPersonalProfile/ConnectionList.web')
);
const RefundManagement = lazy(() =>
  import('../../blocks/RefundManagement/src/RefundManagement')
);
const DailyTimeLimit = lazy(() =>
  import('../../blocks/DailyTimeLimit/src/DailyTimeLimit')
);
const CancellationOfCall = lazy(() =>
  import('../../blocks/CancellationOfCall/src/CancellationOfCall')
);
const History5 = lazy(() => import('../../blocks/History5/src/History5'));
const Referrals = lazy(() => import('../../blocks/Referrals/src/Referrals'));
const AdminConsole3 = lazy(() =>
  import('../../blocks/AdminConsole3/src/AdminConsole3')
);
const ThirdPartyAudioAndVideoIntergration = lazy(() =>
  import('../../blocks/ThirdPartyAudioAndVideoIntergration/src/ThirdPartyAudioAndVideoIntergration')
);
const UserProfileBasicBlock = lazy(() =>
  import('../../blocks/user-profile-basic/src/UserProfileBasicBlock')
);
const UserPersonalProfile = lazy(() =>
  import('../../blocks/user-profile-basic/src/UserPersonalProfile/UserPersonalProfile')
);
const Feedback = lazy(() => import('../../blocks/Feedback/src/Feedback'));
const Categoriessubcategories = lazy(() =>
  import('../../blocks/categoriessubcategories/src/Categoriessubcategories')
);
const CertificationTracking = lazy(() =>
  import('../../blocks/CertificationTracking/src/CertificationTracking')
);
const CountryCodeSelector = lazy(() =>
  import('../../blocks/country-code-selector/src/CountryCodeSelector')
);
const ReviewAndApproval = lazy(() =>
  import('../../blocks/ReviewAndApproval/src/ReviewAndApproval')
);
const ConnectedAccounts = lazy(() =>
  import('../../blocks/ConnectedAccounts/src/ConnectedAccounts')
);
const Followers = lazy(() => import('../../blocks/Followers/src/Followers'));
const PhoneNumberInput = lazy(() =>
  import('../../blocks/mobile-account-registration/src/PhoneNumberInput')
);
const AdditionalDetailForm = lazy(() =>
  import('../../blocks/mobile-account-registration/src/AdditionalDetailForm')
);
const SocialMediaAccountRegistrationScreen = lazy(() =>
  import('../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen')
);
const Chat9 = lazy(() => import('../../blocks/Chat9/src/Chat9'));
const EmailAccountRegistration = lazy(() =>
  import('../../blocks/email-account-registration/src/EmailAccountRegistration')
);
const EmailAccountRegistrationWeb = lazy(() =>
  import('../../blocks/email-account-registration/src/EmailRegister/EmailRegister.web')
);
const RoomDetails = lazy(() =>
  import('../../blocks/DifferentRooms/src/RoomDetails/RoomDetails.web')
);
const UpcomingRooms = lazy(() =>
  import('../../blocks/DifferentRooms/src/UpcomingRooms/UpcomingRooms.web')
);
const OtpVerificationWeb = lazy(() =>
  import('../../blocks/email-account-registration/src/EmailRegister/otpVerification.web')
);
const Splashscreen = lazy(() =>
  import('../../blocks/splashscreen/src/Splashscreen')
);
const ReportUser = lazy(() => import('../../blocks/ReportUser/src/ReportUser'));
const GoogleCalendarSync = lazy(() =>
  import('../../blocks/GoogleCalendarSync/src/GoogleCalendarSync')
);
const DuplicateRooms2 = lazy(() =>
  import('../../blocks/DuplicateRooms2/src/DuplicateRooms2')
);
const DifferentRooms = lazy(() =>
  import('../../blocks/DifferentRooms/src/DifferentRooms')
);
const YourLaneTextOrLinks = lazy(() =>
  import('../../blocks/YourLaneTextOrLinks/src/YourLaneTextOrLinks')
);
const EmailNotifications = lazy(() =>
  import('../../blocks/EmailNotifications/src/EmailNotifications')
);
const QuestionBank = lazy(() =>
  import('../../blocks/QuestionBank/src/QuestionBank')
);
const LikeAPost = lazy(() => import('../../blocks/LikeAPost/src/LikeAPost'));
const InvoiceBilling = lazy(() =>
  import('../../blocks/InvoiceBilling/src/InvoiceBilling')
);
const CfConnect4SocialMediaAccounts4 = lazy(() =>
  import('../../blocks/CfConnect4SocialMediaAccounts4/src/CfConnect4SocialMediaAccounts4')
);
const Interactivefaqs = lazy(() =>
  import('../../blocks/interactivefaqs/src/Interactivefaqs')
);
const AddInteractivefaqs = lazy(() =>
  import('../../blocks/interactivefaqs/src/AddInteractivefaqs')
);
const ContentManagement = lazy(() =>
  import('../../blocks/ContentManagement/src/ContentManagement')
);
const CfTabletSupport7 = lazy(() =>
  import('../../blocks/CfTabletSupport7/src/CfTabletSupport7')
);
const Contactus = lazy(() => import('../../blocks/contactus/src/Contactus'));
const AddContactus = lazy(() =>
  import('../../blocks/contactus/src/AddContactus')
);
const EducationalUserProfile = lazy(() =>
  import('../../blocks/educational-user-profile/src/EducationalUserProfile')
);
const Dashboard = lazy(() => import('../../blocks/dashboard/src/Dashboard'));
const Onboardingguide = lazy(() =>
  import('../../blocks/onboardingguide/src/Onboardingguide')
);
const OnboardingSuccessful = lazy(() =>
  import('../../blocks/onboardingguide/src/OnboardingSuccessful')
);
const OnboardingDetails = lazy(() =>
  import('../../blocks/onboardingguide/src/OnboardingDetails/OnboardingDetails')
);
const ConfirmModerator = lazy(() =>
  import('../../blocks/onboardingguide/src/ConfirmModerator')
);
const ProductTutorial = lazy(() =>
  import('../../blocks/ProductTutorial/src/ProductTutorial')
);
const Settings5 = lazy(() => import('../../blocks/Settings5/src/Settings5'));
const UploadMedia2 = lazy(() =>
  import('../../blocks/UploadMedia2/src/UploadMedia2')
);
const LandingPage = lazy(() =>
  import('../../blocks/landingpage/src/LandingPage')
);
const Setting = lazy(() =>
  import('../../blocks/Settings5/src/settings/setting.web')
);
const ModeratorList = lazy(() =>
  import('../../blocks/DifferentRooms/src/ModeratorList/ModeratorList.web')
);
const Home = lazy(() =>
  import('../../blocks/landingpage/src/LandingPage/Home.web')
);
const MyProfileWeb = lazy(() =>
  import('../../blocks/Settings5/src/settings/MyProfile.web')
);
const TermsAndConditions = lazy(() =>
  import('../../blocks/Settings5/src/settings/Terms.web')
);
const PrivacyPolicyData = lazy(() =>
  import('../../blocks/Settings5/src/settings/PrivacyPolicy.web')
);
const CategoriesWeb = lazy(() =>
  import('../../blocks/categoriessubcategories/src/categories/Category.web')
);
const ModeratorOrientation = lazy(() =>
  import('../../../packages/blocks/onboardingguide/src/ModeratorOrientation')
);
const UserProfile = lazy(() =>
  import('../../blocks/user-profile-basic/src/UserPublicProfile/UserProfile.web')
);
const comingSoon = lazy(() =>
  import('../../blocks/onboardingguide/src/comingSoon.web')
);
const Room = lazy(() =>
  import('../../blocks/ThirdPartyAudioAndVideoIntergration/src/room/Room.web')
);
const RefillWallet = lazy(() =>
  import('../../blocks/Payments/src/refillwallet/RefillWallet.web')
);
const MyWallet = lazy(() =>
  import('../../blocks/Payments/src/Wallet/MyWallet.web')
);
const NotFound = lazy(() =>
  import('../../blocks/email-account-registration/src/EmailRegister/NotFound.web')
);
const ModeratorTerms = lazy(() =>
  import('../../blocks/onboardingguide/src/ModeratorTermsAndPolicy.web')
);
const RoomFeedbackWeb = lazy(() =>
  import('../../blocks/Feedback/src/RoomFeedback.web')
);
const HomePage = lazy(() =>
  import('../../blocks/Referrals/src/HomePage/HomePage.web')
);
const MaintenancePage = lazy(() =>
  import('../../blocks/Referrals/src/MaintenancePage/MaintenancePage.web')
);
const IncorrectTime = lazy(() =>
  import('../../blocks/Referrals/src/IncorrectTime')
);
const AboutPage = lazy(() =>
  import('../../blocks/Referrals/src/AboutPage/AboutPage.web')
);
const Articles = lazy(() =>
  import('../../blocks/blogpostsmanagement/src/BlogpostsManagement.web')
);

const Article = lazy(() =>
  import('../../blocks/blogpostsmanagement/src/BlogContent.web')
);

const FaqPage = lazy(() =>
  import('../../blocks/Referrals/src/FaqPage/FaqPage.web')
);
const ContactUsPage = lazy(() =>
  import('../../blocks/Referrals/src/ContactUsPage/ContactUsPage.web')
);
const HeaderWeb = lazy(() =>
  import('../../blocks/Settings5/src/settings/header.web')
);
const UserEditProfile = lazy(() =>
  import('../../blocks/user-profile-basic/src/UserEditProfile/UserEditProfile.web')
);
const FollowerList = lazy(() =>
  import('../../blocks/user-profile-basic/src/UserPersonalProfile/FollowerList.web')
);
const TouchPoints = lazy(() =>
  import('../../blocks/user-profile-basic/src/UserPersonalProfile/TouchPoints.web')
);
const AdjustVideo = lazy(() =>
  import('../../blocks/ThirdPartyAudioAndVideoIntergration/src/room/VideoChatScreen/AdjustVideo.web')
);

const NewsFeed = lazy(() =>
  import('../../blocks/newsfeed/src/NewsFeed.web')
);

const Polling = lazy(() =>
  import('../../blocks/polling/src/Polling.web')
);

import FirebaseCloudMessage from './FCM';
import { Switch, withRouter } from 'react-router-dom';
import firebase from 'firebase';

const routeMap = {
  NewsFeed: {
    component: NewsFeed,
    path: '/newsfeed'
  },

  Polling: {
    component: Polling,
    path: '/polling'
  },
  
  Room: {
    component: Room,
    path: '/Room/:id'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5'
  },
  RoomFeedbackWeb: {
    component: RoomFeedbackWeb,
    path: '/RoomFeedbackWeb'
  },

  comingSoon: {
    component: comingSoon,
    path: '/comingSoon'
  },
  UserProfile: {
    component: UserProfile,
    path: '/UserProfile'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },

  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  QuestionBank: {
    component: QuestionBank,
    path: '/QuestionBank'
  },
  LikeAPost: {
    component: LikeAPost,
    path: '/LikeAPost'
  },

  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling'
  },
  CfPayoutBankApi: {
    component: CfPayoutBankApi,
    path: '/CfPayoutBankApi'
  },
  CfConnect4SocialMediaAccounts4: {
    component: CfConnect4SocialMediaAccounts4,
    path: '/CfConnect4SocialMediaAccounts4'
  },
  Interactivefaqs: {
    component: Interactivefaqs,
    path: '/Interactivefaqs'
  },
  HomePage: {
    component: HomePage,
    path: '/',
    exact: true,
    normalizedPath: '/',
    guest: true,
  },
  HomePagePublic: {
    component: HomePage,
    path: '/HomePagePublic/:step'
  },
  AboutUs: {
    component: AboutPage,
    path: '/AboutUs',
    guest: true,
    public: true,
  },
  Articles: {
    component: Articles,
    path: '/Articles',
    public: true,
  },
  Article: {
    component: Article,
    path: '/Article/:id',
    public: true,
  },
  Faq: {
    component: FaqPage,
    path: '/Faq',
    guest: true
  },
  ContactUs: {
    component: ContactUsPage,
    path: '/ContactUs',
    guest: true
  },
  AddInteractivefaqs: {
    component: AddInteractivefaqs,
    path: '/AddInteractivefaqs'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  Chat: {
    component: Chat,
    path: '/Chat'
  },
  ChatMessages: {
    component: Chat,
    path: '/Chat/:id'
  },
  CfTabletSupport7: {
    component: CfTabletSupport7,
    path: '/CfTabletSupport7'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  ConnectionList: {
    component: ConnectionList,
    path: '/ConnectionList'
  },
  FollowerList: {
    component: FollowerList,
    path: '/FollowerList'
  },
  TouchPointList: {
    component: TouchPoints,
    path: '/TouchPointList'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: '/Onboardingguide'
  },
  ConfirmModerator: {
    component: ConfirmModerator,
    path: '/ConfirmModerator'
  },
  OnboardingSuccessful: {
    component: OnboardingSuccessful,
    path: '/OnboardingSuccessful'
  },
  OnboardingDetails: {
    component: OnboardingDetails,
    path: '/OnboardingDetails'
  },
  ModuleOrientation: {
    component: ModeratorOrientation,
    path: '/ModuleOrientation/:step?'
  },
  ProductTutorial: {
    component: ProductTutorial,
    path: '/ProductTutorial'
  },
  AnonymousMode: {
    component: AnonymousMode,
    path: '/AnonymousMode'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  PunishmentAlerts: {
    component: PunishmentAlerts,
    path: '/PunishmentAlerts'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  ConversationHistory2: {
    component: ConversationHistory2,
    path: '/ConversationHistory2'
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: '/PaymentAdmin2'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  TabletSupport65: {
    component: TabletSupport65,
    path: '/TabletSupport65'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  PointSystemhearts: {
    component: PointSystemhearts,
    path: '/PointSystemhearts'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  DeepLinking: {
    component: DeepLinking,
    path: '/DeepLinking'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  GiveHearts: {
    component: GiveHearts,
    path: '/GiveHearts'
  },
  LinkShare: {
    component: LinkShare,
    path: '/LinkShare'
  },
  RefundManagement: {
    component: RefundManagement,
    path: '/RefundManagement'
  },
  DailyTimeLimit: {
    component: DailyTimeLimit,
    path: '/DailyTimeLimit'
  },
  CancellationOfCall: {
    component: CancellationOfCall,
    path: '/CancellationOfCall'
  },
  History5: {
    component: History5,
    path: '/History5'
  },
  Referrals: {
    component: Referrals,
    path: '/Referrals'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  ThirdPartyAudioAndVideoIntergration: {
    component: ThirdPartyAudioAndVideoIntergration,
    path: '/ThirdPartyAudioAndVideoIntergration'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  UserPersonalProfile: {
    component: UserPersonalProfile,
    path: '/UserPersonalProfile'
  },
  Feedback: {
    component: Feedback,
    path: '/Feedback'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  CategoriessubcategoriesWeb: {
    component: CategoriesWeb,
    exact: false,
    path: '/Topics',
    public: true
  },
  SubcategoriesTopic: {
    component: CategoriesWeb,
    exact: false,
    path: '/topics/category/:id/subcategory/:sub_id',
    public: true
  },
  TopicRooms: {
    component: CategoriesWeb,
    exact: false,
    path: '/topics/category/:id/subcategory/:sub_id/rooms/:topic_id'
  },
  RefillWallet: {
    component: RefillWallet,
    exact: true,
    path: '/RefillWallet'
    // modal: true
  },
  MyWallet: {
    component: MyWallet,
    exact: true,
    path: '/MyWallet'
  },
  EditProfile: {
    component: UserEditProfile,
    path: '/EditProfile'
  },
  CertificationTracking: {
    component: CertificationTracking,
    path: '/CertificationTracking'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  ConnectedAccounts: {
    component: ConnectedAccounts,
    path: '/ConnectedAccounts'
  },
  Followers: {
    component: Followers,
    path: '/Followers'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Chat9: {
    component: Chat9,
    path: '/Chat9'
  },

  registration: {
    component: EmailAccountRegistrationWeb,
    path: '/registration'
  },

  MyProfileWeb: {
    component: MyProfileWeb,
    path: '/MyProfileWeb'
  },

  RoomDetails: {
    expect: true,
    component: RoomDetails,
    path: '/RoomDetailsWeb/:id'
  },

  UpcomingRooms: {
    component: UpcomingRooms,
    path: '/Rooms'
  },

  otpVerification: {
    component: OtpVerificationWeb,
    path: '/OtpVerification'
  },

  Setting: {
    component: Setting,
    path: '/Setting'
  },
  // HomeWeb: {
  //   component: HomeWeb,
  //   path: '/Homeweb'
  // },

  ModeratorList: {
    component: ModeratorList,
    path: '/ModeratorList'
  },

  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions',
    guest: true
  },

  ModeratorTerms: {
    component: ModeratorTerms,
    path: '/ModeratorTerms'
  },

  PrivacyPolicy: {
    component: PrivacyPolicyData,
    path: '/PrivacyPolicy',
    guest: true
  },

  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen'
  },
  ReportUser: {
    component: ReportUser,
    path: '/ReportUser'
  },
  GoogleCalendarSync: {
    component: GoogleCalendarSync,
    path: '/GoogleCalendarSync'
  },
  DuplicateRooms2: {
    component: DuplicateRooms2,
    path: '/DuplicateRooms2'
  },
  DifferentRooms: {
    component: DifferentRooms,
    path: '/DifferentRooms'
  },
  YourLaneTextOrLinks: {
    component: YourLaneTextOrLinks,
    path: '/YourLaneTextOrLinks'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  IncorrectTime: {
    component: IncorrectTime,
    path: '/incorrect-time',
    public: true
  },
  AdjustSettings: {
    component: AdjustVideo,
    path: '/adjust-settings/:id'
  },
  // AlertWeb: {
  //   component: AlertBlock,
  //   path: '*/AlertWeb',
  //   modal: true
  // },
  NotFound: {
    component: NotFound,
    path: '*'
    // exact: true
  }
};




class App extends BlockComponent {
  fetchMaintenanceId;
  verifyLocalTimeId;
  verifyCurrentTime;
  chatActionCable;

  constructor(props) {
    super(props);
    this.previousLocation = null;

    this.state = {
      isMaintenanceOn: false,
      userToken: localStorage.getItem('token')
    };

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  attachChatsCabel = () => {
    const token = localStorage.getItem('token');
    let user_id = parseInt(localStorage.getItem('userId') || '0');

    // initialize cable
    const cable = actionCable.createConsumer(
      `${x.baseURL}/cable?token=${token}`
    );

    // set cabel to this.chatActionCable
    this.chatActionCable = cable;

    // connect to chats channel
    cable.subscriptions.create(
      {
        channel: 'ChatsChannel',
        user_id
      },
      {
        connected: () => {
          console.log('CONNECTED ChatsChannel');
        },
        disconnected: () => {
          console.log('DISCONNECTED ChatsChannel');
        },
        received: data => {
          console.log(data);
        }
      }
    );
  };

  handleActivityTracking = async () => {
    const token = await getStorageData("token")
    if(!token) return false

    const header = {
      'Content-Type': 'application/json',
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/home_page_settings/account_tracking`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "app_status": 0,
        "devise_type": "Web"
      })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUnload = (event) => {
    event.preventDefault();
    this.handleActivityTracking()
    return false
  };

  getTestIpInfor = () => {
    const queryParams = new URLSearchParams(this.props.location.search);
    const ipinfor = queryParams.get('ipinfor');
    return ipinfor
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
  }

  componentDidMount() {
    // Call Maintenance api to get current status
    window.addEventListener('beforeunload', this.handleUnload);

    this.fetchMaintenanceData();

    // Connect to Maintenances Channel to get update about Maintenance
    const cable = actionCable.createConsumer(`${x.baseURL}/cable`);
    cable.subscriptions.create(
      {
        channel: 'MaintenancesChannel'
      },
      {
        connected: () => {
          // console.log("CONNECTED");
        },
        disconnected: () => {
          // console.log("DISCONNECTED");
        },
        received: data => {
          this.setState({
            isMaintenanceOn: data?.maintenances_on === '1'
          });
          // console.log(data?.maintenances_on === "1")
        }
      }
    );

    // connect to chats channel if user is logged in
    if (isAuthenticated()) {
      this.attachChatsCabel();
    }

    this.unlisten = this.props.history.listen((location, action) => {
      if (location.pathname === '/incorrect-time') return;
      if (action === 'PUSH' || action === 'REPLACE' || action === 'POP') {
        if (location.pathname !== this.previousLocation?.pathname) {
          this.verifyLocalTime();
        }
        this.previousLocation = location;
      }
    });

    this.verifyLocalTime();
    window.addEventListener('focus', this.verifyLocalTime);
    sessionStorage.removeItem('notification_type');

    // Check if service worker is available
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', this.handleRedirect);

      navigator.serviceWorker.onmessage = event => {
        setTimeout(() => {
          const notificationType = sessionStorage.getItem('notification_type');
          if (notificationType === 'foreground') {
            sessionStorage.removeItem('notification_type');
            return;
          }

          const redirect_url = event?.data?.notification?.click_action;

          if (redirect_url) {
            this.props.history.push(redirect_url);
          }
        }, 1000);
        // if the message has a URL, navigate to it
      };
    }
  }


  async componentWillUnmount() {
    // disconnect from chats channel
    if (this.chatActionCable) {
      this.chatActionCable.disconnect();
    }

    // remove listener from location change
    if (typeof this.unlisten === 'function') {
      this.unlisten();
    }

    // remove listener from window focus
    window.removeEventListener('focus', this.verifyLocalTime);

    // remove listener from service worker
    if (navigator.serviceWorker) {
      navigator.serviceWorker.removeEventListener(
        'message',
        this.handleRedirect
      );
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const userToken = localStorage.getItem('token');
      if (userToken) {
        if (userToken !== this.state.userToken) {
          this.setState({ userToken });
          this.attachChatsCabel();
        }
      }
    }
  }

  handleFetchMaintenanceId = (apiRequestCallId, responseJson) => {
    if (apiRequestCallId === this.fetchMaintenanceId) {
      this.setState({
        isMaintenanceOn:
          responseJson.data?.find(item => item.platform === 'WEB')
            ?.maintenances_on ?? false
      });
    }
  };

  handleVerifyLocalTimeId = async(apiRequestCallId, responseJson) => {
    const testIpInfor = this.getTestIpInfor()
    const isGoogle = await isUserIpInGoogleIps(testIpInfor)
    console.log('======is google======', isGoogle)
    if(isGoogle) return
    if (apiRequestCallId === this.verifyLocalTimeId) {
      const pathname = this.props.history.location.pathname;
      const isTimeZoneWrong = JSON.parse(
        sessionStorage.getItem('isTimeZoneWrong')
      );
      if (responseJson?.errors) {
        if (isTimeZoneWrong) return;
        this.getCurrentTime();

        if (pathname !== '/incorrect-time') {
          let redirectRoute = this.props.history.location.pathname;
          if (this.props.history.location.search) {
            redirectRoute += this.props.history.location.search;
          }
          sessionStorage.setItem('redirect-route', redirectRoute);
        }
        this.props.history.replace('/incorrect-time', {
          state: {
            actualDT: new Date(responseJson.actual_time).toLocaleString()
          }
        });
      } else {
        if (isTimeZoneWrong) {
          sessionStorage.removeItem('isTimeZoneWrong');
        }

        if (pathname === '/incorrect-time') {
          const redirect_to = sessionStorage.getItem('redirect-route');
          if (!redirect_to) {
            this.props.history.replace('/Rooms');
            return;
          }

          this.props.history.replace(redirect_to, {
            ...this.previousLocation?.state
          });
        }
      }
    }
  };

  handleVerifyCurrentTime = (apiRequestCallId, responseJson) => {
    if (this.verifyCurrentTime === apiRequestCallId) {
      if (responseJson?.abbreviation) {
        localStorage.setItem('abbreviation', responseJson?.abbreviation);
      }
    }
  };

  async receive(from, message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.handleFetchMaintenanceId(apiRequestCallId, responseJson);
        this.handleVerifyLocalTimeId(apiRequestCallId, responseJson);
        this.handleVerifyCurrentTime(apiRequestCallId, responseJson);
      }
    }
  }

  fetchMaintenanceData = () => {
    const headers = {
      'Content-Type': 'application/json'
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchMaintenanceId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/onboarding_questions/version_managment'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getCurrentTime = () => {
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyCurrentTime = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `https://worldtimeapi.org/api/timezone/${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  verifyLocalTime = () => {
    const headers = {
      'Content-Type': 'application/json',
      TZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      UTime: Math.floor(new Date().getTime() / 1000).toString()
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyLocalTimeId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_settings/home_page_settings/verify_request'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  updateFCMToken = (oldToken, newToken) => {
    const headers = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('token')
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/update_device_token'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );

    // body
    const payload = { last_device_id: oldToken, new_device_id: newToken };

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  // if tab is already open, and user receives a notification
  // focus and redirect the user to the page.
  handleRedirect = event => {
    const redirectURL = event.data.url;

    if (redirectURL) {
      const url = new URL(redirectURL);
      const path = url.pathname + url.search;

      this.props.history.push(path);
    }
  };

  mainBg = () => {
    const url = window.location.href.toLowerCase();
    const sitesHaveBlueBg = ['userprofile', 'userpersonalprofile']
    const hasBlueBg = sitesHaveBlueBg.some(str => url.includes(str.toLocaleLowerCase()));

    const sitesHaveGrayBg = ['EditProfile'] 
    const hasGrayBg = sitesHaveGrayBg.some(str => url.includes(str.toLocaleLowerCase()));

    if(hasBlueBg) {
      return '#F1F5F9'
    }

    if(hasGrayBg) {
      return '#FBFBFB'
    }

    return 'none'
  }

  render() {
    const NoHeader = () => {
      const pathname = this.props.location.pathname;
      if (
        pathname?.toLowerCase().includes('/topics') &&
        !pathname.includes('/rooms/')
      ) {
        return !isAuthenticated();
      }

      if(pathname.toLowerCase().includes('article')) return true

      switch (pathname?.toLowerCase()) {
        case '/homepage/registration':
          return true;
        case '/registration':
          return true;
        case '/onboardingguide':
          return true;
        case '/':
          return true;
        case '/onboardingsuccessful':
          return true;
        case '/termsandconditions':
          return true;
        case '/privacypolicy':
          return true;
        case '/otpverification':
          return true;
        case '/confirmmoderator':
          return true;
        case '/aboutus':
        case '/articles':
        case '/article':
          return true;
        case '/blogcontent':
          return true;
        case '/homepage':
          return true;
        case '/faq':
          return true;
        case '/contactus':
          return true;
        case '/homepagepublic/4':
          return true;
        case '/moderatorterms':
          return true;
        case '/incorrect-time':
          return true;
      }
    };

    return (
      <div
        id="main-view"
        style={{
          width: '100vw',
          background: this.mainBg(),
          overflowY:window.location.pathname?.toLowerCase() === '/rooms' &&
            window.location.pathname?.toLowerCase() === '/rooms'
              ? 'hidden'
              : 'auto'
        }}
      >
        <>
          {/* In iPhone Safari browser FCM is not supported. */}
          {firebase.messaging.isSupported() && (
            <FirebaseCloudMessage updateFCMToken={this.updateFCMToken} />
          )}
          {process.env.NODE_ENV === 'production' && (
            <Helmet>
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-XN6NLTEYJT"
              />
              <script>
                {`
                    window.dataLayer = window.dataLayer || []; 
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date()); 
                    gtag('config', 'G-XN6NLTEYJT');
                `}
              </script>
              <script type="text/javascript">
                {`
                    (function(c,l,a,r,i,t,y){ 
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; 
                        t=l.createElement(r);
                        t.async=1;
                        t.src="https://www.clarity.ms/tag/"+i; 
                        y=l.getElementsByTagName(r)[0];
                        y.parentNode.insertBefore(t,y); 
                    })(window, document, "clarity", "script", "i294h6xjs1"); 
                `}
              </script>
              <script>
                {`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '226751626785492');
                    fbq('track', 'PageView');
                `}
              </script>
              <script type="application/ld+json">
              {`
                {
                  "@context": "http://schema.org",
                  "@type": "SoftwareApplication",
                  "name": "YLanes",
                  "applicationCategory": "Social Networking",
                }
              `}
              </script>
            </Helmet>
          )}
          <Suspense
            fallback={
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100vh"
              >
                <CircularProgress style={{ color: '#FFCB00' }} />
              </Box>
            }
          >
            <StateProvider
              children={
                <>
                  {this.state.isMaintenanceOn ? (
                    <MaintenancePage />
                  ) : (
                    <>
                      {NoHeader() ? (
                        <></>
                      ) : (
                        <HeaderWeb
                          navigation={{ navigate: this.props.history.push }}
                          appProps={this.props}
                          routeMap={routeMap}
                        />
                      )}
                      <Switch>
                        {WebRoutesGenerator({ routeMap })}
                        <ModalContainer />
                      </Switch>
                    </>
                  )}
                </>
              }
            />
          </Suspense>
        </>
      </div>
    );
  }
}

export default withRouter(App);
